.ingredients-list {


  .ingredients-row {
    display: flex;

    .col-drag-handle {
      flex-basis: 30px;
      align-self: center;
    }

    .col-ingredient {

    }

    .col-quantity {
      flex-basis: 80px;
    }

    .col-unit {
      flex-basis: 100px;
    }

    .col-action {
      flex-basis: 40px;
    }
  }
}
