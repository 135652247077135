.filter-box {
  background: #ffffff;
  margin-right: 15px;
  min-width: 160px;

  .filter-label {
    padding: 10px 15px;
  }

  .filter-icon {
    margin-right: 10px;
  }
}

.date-filter {
  min-width: 220px;
}

.report-stat-card {
  width: 270px;
  padding: 15px 25px;
}
