@import "variables";
@import "mixins/breakpoints";
@import "mixins/text";
@import "functions";
@import "utilities/utilities";
@import "utilities/spacing";
@import "utilities/text";
@import "utilities/flex";
@import "utilities/float";
@import "utilities/visibility";


.d-form {
  .d-table {
    table-layout: fixed;
  }

  .d-table-cell,
  .d-block {
    @include set-padding(0, 0, 3, 0); //0 16px 24px 0;

    &:not(:last-child) {
      padding-right: padding(4);
    }

    &:only-child:not(.width-100) {
      padding-right: padding(4);
    }
  }

  @media screen and(max-width: map-get($grid-breakpoints, 'sm')) {
    .d-table-cell,
    .d-block {
      display: block;
      width: 100%;
    }
  }
}

.d-table {
  display: table;
  width: 100%;
}

.d-table-cell {
  display: table-cell;
}

.width-25 {
  width: 25%;
}

.width-33 {
  width: 33%;
}

.width-50 {
    width: 50%;
}

.width-66 {
  width: 67%;
}

.width-100 {
  width: 100%;
}
