@import "../variables";
@import "../functions";

.image-element {
  position: relative;
  margin: auto;
  max-width: 140px;

  /*&:hover {
    .image-preview:after {
      content: ' ';
      background: #ffffffc9;
      position: absolute;
      top: 0;
      left: 0;
       right: 0;
       bottom: 0;
      margin: 1px;
    }

    .image-buttons {
      display: block;
    }
  }*/


  .image-preview {
    width: 140px;
    height: 140px;
    margin: auto;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }

  .image-buttons {
    position: absolute;
    bottom: 0px;
    left: 0;
    /* background: #ffffff4d; */
    padding: 4px 8px;
    border: 0;
    background: 0;
    top: 0;
    right: 0;
    text-align: center;
    cursor: pointer;

    .loading-icon {
      transform: translateY(-50%);
      margin-top: 50%;
    }

    > button {
      margin-right: 10px;
      font-size: 3rem;
      padding: 0;
      transform: translateY(-50%);
      margin-top: 50%;

      &:hover {
        background-color: transparent;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  img {
    width: auto;
    max-width: 90%;
    transform: translateY(-50%);
    margin-top: 50%;
    max-height: 90%;
  }
}
