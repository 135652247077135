.sticky-outer-wrapper {

  &.active {
    .sticky-inner-wrapper {
      margin-left: -24px;
      padding: 0 24px;
      margin-right: -24px;
      background: #fff;
      width: 100% !important;

      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
}
